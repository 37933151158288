export default {
  title: "المصورين",
  entityName: "photographer",
  properties: [
    {
      text: "#UID",
      value: "id",
      align: "right",
      field: "_id",
      sortable: false,
    },
    {
      text: "المصور",
      value: "name",
      align: "right",
      field: "_name",
      sortable: false,
    },
  ],
  getHeader() {
    let newArr = [];

    this.properties.forEach(({ text, value, align, sortable }) => {
      newArr.push({ text, value, align, sortable });
    });

    return newArr;
  },
  getSortDropdown() {
    let newArr = [];

    this.properties.forEach(({ text, field }) => {
      newArr.push({ text, field });
    });

    return newArr;
  },
};
